import React, { useEffect, useRef, useState } from "react";
import Bg from "../../assets/newassets/interior-tablet/BG.jpg";
import Base from "../../assets/newassets/interior-tablet/basemerah.png";
import Back from "../../assets/ledinteractive/TABLET/scene2/back.png";
import { useNavigate } from "react-router-dom";

const Interior = () => {
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // Create WebSocket connection.
    const socketInstance = new WebSocket(
      "wss://api-mmksi.giias2024.mahakreasi.com/"
    );

    // Connection opened
    socketInstance.addEventListener("open", (event) => {
      console.log("Connected to WebSocket server");
    });

    // Listen for messages
    socketInstance.addEventListener("message", (event) => {
      console.log("Message from server ", event.data);
    });

    // Connection closed
    socketInstance.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    setSocket(socketInstance);

    // Cleanup on component unmount
    return () => {
      socketInstance.close();
    };
  }, []);

  const handleClick = (dotNumber) => {
    const action = `action${dotNumber}`;
    const type = "interior";
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    setIsDisabled(true); // Disable all buttons
    setTimeout(() => {
      setIsDisabled(false); // Re-enable all buttons after 10 seconds
    }, 10000);
  };

  const handleBack = () => {
    const action = "back";
    const type = "interior";
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    setIsDisabled(true); // Disable all buttons
    setTimeout(() => {
      setIsDisabled(false); // Re-enable all buttons after 10 seconds
    }, 10000);
    navigate("/");
  };

  const handleClickOutside = (event) => {
    // Implement any outside click logic if needed
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="flex flex-col justify-center items-center h-screen w-screen space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="flex flex-col justify-center space-y-[730px]">
        <div className="space-y-[-70px]">
          <img className="h-auto w-[800px]" src={Base} alt="Base" />
          <p className="text-gray-200 uppercase font-mmcOffice font-bold italic text-3xl text-center">
            WHERE PRESTIGE MEETS INNOVATION
          </p>
        </div>
        <p className="text-white uppercase italic font-mmcOffice text-2xl text-center">
          Press the button to know more details
        </p>
      </div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[120px] left-[130px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(1);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[280px] left-[230px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(2);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[150px] left-[400px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(3);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[490px] left-[630px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(4);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[350px] left-[720px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(5);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[380px] right-[320px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(6);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[280px] right-[50px] cursor-pointer breathing-dot ${isDisabled ? "filter grayscale" : ""}`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(7);
          }
        }}
      ></div>
      <button
        className="absolute bottom-5 left-5 h-auto w-52"
        onClick={() => handleBack()}
        disabled={isDisabled}
      >
        <img className="" src={Back} alt="Go Back" />
      </button>
    </div>
  );
};

export default Interior;
