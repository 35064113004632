import React, { useState, useRef, useEffect } from "react";
import ExteriorCar from "../../assets/ledinteractive/TABLET/scene2/mobil.png";
import LogoXforce from "../../assets/ledinteractive/TABLET/scene2/logoxforce.png";
import Base from "../../assets/ledinteractive/TABLET/scene2/base.png";
import Bg from "../../assets/newassets/exterior-tablet/2/BG.mp4";
import BaseMov from "../../assets/newassets/exterior-tablet/1/base.mov";
import Back from "../../assets/ledinteractive/TABLET/scene2/back.png";
import IfLogo from "../../assets/newassets/exterior-tablet/2/iflogo.png";
import { useNavigate } from "react-router-dom";

const Exterior = () => {
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    // Create WebSocket connection.
    const socketInstance = new WebSocket(
      "wss://api-mmksi.giias2024.mahakreasi.com/"
    );

    // Connection opened
    socketInstance.addEventListener("open", (event) => {
      console.log("Connected to WebSocket server");
    });

    // Listen for messages
    socketInstance.addEventListener("message", (event) => {
      console.log("Message from server ", event.data);
    });

    // Connection closed
    socketInstance.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    setSocket(socketInstance);

    // Cleanup on component unmount
    return () => {
      socketInstance.close();
    };
  }, []);

  const handleClick = (dotNumber) => {
    const action = `action${dotNumber}`;
    const type = "exterior";
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    setIsDisabled(true); // Disable all buttons
    setTimeout(() => {
      setIsDisabled(false); // Re-enable all buttons after 10 seconds
    }, 10000);
  };

  const handleBack = () => {
    const action = "back";
    const type = "exterior";
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    setIsDisabled(true); // Disable all buttons
    setTimeout(() => {
      setIsDisabled(false); // Re-enable all buttons after 10 seconds
    }, 10000);
    navigate("/");
  };

  const handleClickOutside = (event) => {
    // Implement any outside click logic if needed
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex flex-col justify-center items-center h-full w-full space-y-24">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        src={Bg}
        autoPlay
        loop
        muted
      ></video>
      <div className="absolute space-y-[-110px] top-[80px]">
        <img className="h-auto w-[1300px]" src={Base} alt="Base" />
        {/* <video
          className="top-0 left-0 w-[1300px] h-full object-cover z-0"
          src={BaseMov}
          autoPlay
          loop
          muted
        ></video> */}
        <p className="text-white uppercase italic font-bold font-mmcOfficeBold text-3xl text-center z-30">
          IMPRESSIVE FUTURISTIC DESIGN, STANDS OUT FROM THE CROWD
        </p>
      </div>
      <img
        className="absolute top-[270px] h-auto w-[1000px]"
        src={ExteriorCar}
        alt="Mobil"
      />
      <img
        src={IfLogo}
        className="absolute right-[-50px] top-[-110px] w-fit h-fit scale-50"
        alt=""
      />
      <p className="absolute right-[28px] top-[0px] font-semibold text-right w-[240px] font-mmcOffice text-sm text-black">
        Mitsubishi Xforce meraih penghargaan iF Design Award 2024 atas unsur
        estetika dan fungsionalitas desain**
      </p>
      <p className="uppercase absolute bottom-16 italic font-mmcOffice text-2xl text-black text-center">
        Press the button to know more details
      </p>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[410px] left-[280px] cursor-pointer breathing-dot ${
          isDisabled ? "filter grayscale" : ""
        }`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(1);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[410px] left-[240px] cursor-pointer breathing-dot ${
          isDisabled ? "filter grayscale" : ""
        }`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(2);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[280px] left-[370px] cursor-pointer breathing-dot ${
          isDisabled ? "filter grayscale" : ""
        }`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(3);
          }
        }}
      ></div>
      <div
        className={`absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[400px] right-[230px] cursor-pointer breathing-dot ${
          isDisabled ? "filter grayscale" : ""
        }`}
        onClick={() => {
          if (!isDisabled) {
            handleClick(4);
          }
        }}
      ></div>
      <button
        className="absolute bottom-10 left-10 h-auto w-52"
        onClick={() => handleBack()}
        disabled={isDisabled}
      >
        <img className="" src={Back} alt="Go Back" />
      </button>
    </div>
  );
};

export default Exterior;
