import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Exterior from "../../assets/newassets/exterior-tablet/1/EXTERIOR.png";
import Interior from "../../assets/newassets/exterior-tablet/1/INTERIOR.png";
import InteriorSafety from "../../assets/newassets/exterior-tablet/1/SAFETY.png";
import LogoXforce from "../../assets/ledinteractive/TABLET/scene1/logoxforce.png";
import Base from "../../assets/ledinteractive/TABLET/scene1/base.png";
import BG from "../../assets/newassets/exterior-tablet/2/bgbaru.jpg";

const MainMenu = () => {
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Create WebSocket connection.
    const socketInstance = new WebSocket(
      "wss://api-mmksi.giias2024.mahakreasi.com/"
    );

    // Connection opened
    socketInstance.addEventListener("open", (event) => {
      console.log("Connected to WebSocket server");
    });

    // Listen for messages
    socketInstance.addEventListener("message", (event) => {
      console.log("Message from server ", event.data);
    });

    // Connection closed
    socketInstance.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    setSocket(socketInstance);

    // Cleanup on component unmount
    return () => {
      socketInstance.close();
    };
  }, []);

  const handleClick = (path) => {
    const action = "";
    const type = path;
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    navigate(`/${path}`);
  };

  return (
    <div className="h-screen w-screen">
      <div
        className="flex flex-col justify-center items-center h-full space-y-4 bg-cover bg-center"
        style={{ backgroundImage: `url(${BG})` }}
      >
        <img
          className="h-auto w-[700px] invert mb-[-10px]"
          src={LogoXforce}
          alt="Logo"
        />
        <div className="space-y-[-75px] pb-24">
          <img className="h-auto w-[1100px]" src={Base} alt="Base" />
          <p className="text-white uppercase italic font-mmcOfficeBold text-3xl text-md text-center">
            Let's Explore Your Life's Adventure Partner
          </p>
        </div>
        <div className="space-y-[-43px]">
          <div className="space-y-[-58px] relative z-20">
            <div className="flex space-x-[-90px]">
              <button onClick={() => handleClick("exterior")}>
                <img
                  className="btnimage h-auto w-auto shadow-lg transform transition-transform duration-200 active:scale-95 animate-breath"
                  src={Exterior}
                  alt="Exterior"
                />
              </button>
              <button onClick={() => handleClick("interior")}>
                <img
                  className="btnimage h-auto w-auto shadow-lg transform transition-transform duration-200 active:scale-95 animate-breath"
                  src={Interior}
                  alt="Interior"
                />
              </button>
              <button onClick={() => handleClick("safety")}>
                <img
                  className="btnimage h-auto w-auto shadow-lg transform transition-transform duration-200 active:scale-95 animate-breath"
                  src={InteriorSafety}
                  alt="Interior Safety"
                />
              </button>
            </div>
            <div className="flex justify-center left-[-5px] text-2xl space-x-[270px] relative z-30">
              <p className="font-mmcOfficeBold italic pr-10 text-white">
                EXTERIOR
              </p>
              <p className="font-mmcOfficeBold italic text-white">INTERIOR</p>
              <p className="font-mmcOfficeBold italic text-white">
                SAFETY FEATURES
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
