import React from 'react';
import Base from '../assets/ledinteractive/TABLET/scene1/base.png';

const Loading = () => {
  return (
    <div className='flex flex-col justify-center items-center w-screen h-screen bg-black space-y-[-70px]'>
      <img className='h-auto w-[1000px]' src={Base} alt="Loading" /> 
      <p className="text-white font-mmcOfficeBold text-4xl italic font-bold animate-pulse">LOADING...</p>
    </div>
  );
};

export default Loading;
