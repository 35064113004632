import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingScreen from "./layouts/Loading";
import MainMenu from "./layouts/tablet/MainMenu";
import Exterior from "./layouts/tablet/Exterior";
import Interior from "./layouts/tablet/Interior";
import InteriorSafety from "./layouts/tablet/InteriorSafety";
import IdleMode from "./layouts/IdleMode";
import UserInteractionHandler from "./layouts/UserInteractionHandler";
import LedInterior from "./layouts/led/InteriorLed";
import LedExterior from "./layouts/led/ExteriorLed";
import LedSafety from "./layouts/led/InteriorSafetyLed";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="h-screen flex justify-center align-middle">
      <Router>
        {loading ? (
          <LoadingScreen />
        ) : (
          // <UserInteractionHandler>
          <Routes>
            <Route path="/" exact element={<MainMenu />} />
            <Route path="/loading" exact element={<LoadingScreen />} />
            <Route path="/idle" exact element={<IdleMode />} />
            <Route path="/exterior" exact element={<Exterior />} />
            <Route path="/interior" exact element={<Interior />} />
            <Route path="/safety" exact element={<InteriorSafety />} />

            <Route path="/led/exterior" exact element={<LedExterior />} />
            <Route path="/led/interior" exact element={<LedInterior />} />
            <Route path="/led/safety" exact element={<LedSafety />} />
          </Routes>
          // </UserInteractionHandler>
        )}
      </Router>
    </div>
  );
}

export default App;
