import React from "react";
import Bg from '../assets/ledinteractive/LED/scene1/videodummy.png';

const IdleMode = () => {
  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    ></div>
  );
};

export default IdleMode;
