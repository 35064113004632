import React, { useEffect, useRef, useState } from "react";
import Bg from "../../assets/interiorsafety.png";
import Logo from "../../assets/ledinteractive/TABLET/scene1/logoxforce.png";
import Back from "../../assets/ledinteractive/TABLET/scene2/back.png";
import { useNavigate } from "react-router-dom";

const InteriorSafety = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [activeDot, setActiveDot] = useState(null);
  const popupRef1 = useRef(null);
  const popupRef2 = useRef(null);
  const popupRef3 = useRef(null);
  const popupRef4 = useRef(null);
  const navigate = useNavigate();

  const handleClick = (dotNumber) => {
    console.log(`Dot ${dotNumber} clicked`);
    setPopupVisible(true);
    setActiveDot(dotNumber);
  };

  const handleClose = () => {
    setPopupVisible(false);
    setActiveDot(null);
  };

  const handleClickOutside = (event) => {
    if (activeDot) {
      const refs = [popupRef1, popupRef2, popupRef3, popupRef4];
      const clickedInsideAnyPopup = refs.some(
        (ref) => ref.current && ref.current.contains(event.target)
      );
      if (!clickedInsideAnyPopup) {
        setPopupVisible(false);
        setActiveDot(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeDot]);

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="flex flex-col justify-center space-y-[310px]">
        <div className="space-y-4 flex flex-col items-center">
          <img className="h-auto w-[500px]" src={Logo} alt="Base" />
          <p className="text-white uppercase font-bold font-mmcOfficeBold text-3xl text-center">
            DRIVE THE EXCITEMENT WITH CONFIDENCE
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex-row">
            <button className="h-auto w-52" onClick={() => navigate("/interior-safety")}>
              <img className="" src={Back} alt="Go Back" />
            </button>
            <button className="h-auto w-52" onClick={() => navigate("/interior-safety")}>
              <img className="" src={Back} alt="Go Back" />
            </button>
            <button className="h-auto w-52" onClick={() => navigate("/interior-safety")}>
              <img className="" src={Back} alt="Go Back" />
            </button>
          </div>
          <div className="flex-row">
            <button className="h-auto w-52" onClick={() => navigate("/interior-safety")}>
              <img className="" src={Back} alt="Go Back" />
            </button>
            <button className="h-auto w-52" onClick={() => navigate("/interior-safety")}>
              <img className="" src={Back} alt="Go Back" />
            </button>
            <button className="h-auto w-52" onClick={() => navigate("/interior-safety")}>
              <img className="" src={Back} alt="Go Back" />
            </button>
          </div>
        </div>
        <p className="text-white uppercase italic font-mmcOfficeBold text-2xl text-center">
          Press the button to know more details
        </p>
      </div>
      <button
        className="absolute bottom-5 left-5 h-auto w-52"
        onClick={() => navigate("/")}
      >
        <img className="" src={Back} alt="Go Back" />
      </button>
    </div>
  );
};

export default InteriorSafety;
