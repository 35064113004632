import React, { useState, useRef, useEffect } from "react";
import Bg from "../../assets/newassets/exterior-tablet/2/BG.mp4";

const Exterior = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-10"
        src={Bg}
        autoPlay
        loop
        muted
      ></video>
    </div>
  );
};

export default Exterior;
