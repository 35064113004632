import React, { useEffect, useState } from "react";
import Bg from "../../assets/newassets/exterior-tablet/2/bgbaru.jpg";
import Logo from "../../assets/ledinteractive/TABLET/scene1/logoxforce.png";
import Back from "../../assets/ledinteractive/TABLET/scene2/back.png";
import Base from "../../assets/newassets/safety-tablet/BASE.png";
import { useNavigate } from "react-router-dom";

const InteriorSafety = () => {
  const [socket, setSocket] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Create WebSocket connection.
    const socketInstance = new WebSocket(
      "wss://api-mmksi.giias2024.mahakreasi.com/"
    );

    // Connection opened
    socketInstance.addEventListener("open", (event) => {
      console.log("Connected to WebSocket server");
    });

    // Listen for messages
    socketInstance.addEventListener("message", (event) => {
      console.log("Message from server ", event.data);
    });

    // Connection closed
    socketInstance.addEventListener("close", () => {
      console.log("Disconnected from WebSocket server");
    });

    setSocket(socketInstance);

    // Cleanup on component unmount
    return () => {
      socketInstance.close();
    };
  }, []);

  const handleClick = (dotNumber) => {
    const action = `action${dotNumber}`;
    const type = "safety";
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    setIsDisabled(true); // Disable all buttons
    setTimeout(() => {
      setIsDisabled(false); // Re-enable all buttons after 10 seconds
    }, 10000);
  };

  const handleBack = () => {
    const action = "back";
    const type = "safety";
    if (socket) {
      socket.send(JSON.stringify({ action, type }));
      console.log(`Sent: ${action}, ${type}`);
    }
    setIsDisabled(true); // Disable all buttons
    setTimeout(() => {
      setIsDisabled(false); // Re-enable all buttons after 10 seconds
    }, 10000);
    navigate("/");
  };

  const handleClickOutside = (event) => {
    // Implement any outside click logic if needed
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="flex flex-col justify-center space-y-8">
        <div className="space-y-6 flex flex-col items-center">
          <img className="h-auto w-[500px] invert" src={Logo} alt="Base" />
          <p className="text-black uppercase font-bold font-mmcOfficeBold text-3xl text-center">
            DRIVE THE EXCITEMENT WITH CONFIDENCE
          </p>
        </div>
        <div className="flex flex-col">
          <div className="relative flex flex-row space-x-[-60px]">
            <button
              className="relative h-auto w-[450px] btnimage"
              onClick={() => handleClick(1)}
              disabled={isDisabled}
            >
              <img
                className={`relative z-10 ${isDisabled ? "filter grayscale" : ""}`}
                src={Base}
                alt="Base"
              />
              <p className="absolute inset-[55px] ml-[85px] font-mmcOfficeBold text-[23px] w-60 text-center italic pr-10 text-white tracking-wider z-20">
                DRIVE MODE
              </p>
            </button>
            <button
              className="relative h-auto w-[450px] btnimage"
              onClick={() => handleClick(2)}
              disabled={isDisabled}
            >
              <img
                className={`relative z-10 ${isDisabled ? "filter grayscale" : ""}`}
                src={Base}
                alt="Base"
              />
              <p className="absolute inset-[40px] ml-[85px] font-mmcOfficeBold text-[23px] w-60 text-center italic text-white tracking-wider z-20">
                HIGH GROUND CLEARANCE
              </p>
            </button>
            <button
              className="relative h-auto w-[450px] btnimage"
              onClick={() => handleClick(3)}
              disabled={isDisabled}
            >
              <img
                className={`relative z-10 ${isDisabled ? "filter grayscale" : ""}`}
                src={Base}
                alt="Base"
              />
              <p className="absolute inset-[40px] ml-[85px] font-mmcOfficeBold text-[23px] w-60 text-center italic text-white tracking-wider z-20">
                REAR CROSS TRAFFIC ALERT
              </p>
            </button>
          </div>
          <div className="relative flex flex-row space-x-[-60px]">
            <button
              className="relative h-auto w-[450px] btnimage"
              onClick={() => handleClick(4)}
              disabled={isDisabled}
            >
              <img
                className={`relative z-10 ${isDisabled ? "filter grayscale" : ""}`}
                src={Base}
                alt="Base"
              />
              <p className="absolute inset-[40px] ml-[85px] font-mmcOfficeBold text-[23px] w-60 text-center italic pr-10 text-white tracking-wider z-20">
                ACTIVE YAW CONTROL
              </p>
            </button>
            <button
              className="relative h-auto w-[450px] btnimage"
              onClick={() => handleClick(5)}
              disabled={isDisabled}
            >
              <img
                className={`relative z-10 ${isDisabled ? "filter grayscale" : ""}`}
                src={Base}
                alt="Base"
              />
              <p className="absolute inset-[40px] ml-[85px] font-mmcOfficeBold text-[23px] w-60 text-center italic text-white tracking-wider z-20">
                BLIND SPOT WARNING
              </p>
            </button>
            <button
              className="relative h-auto w-[450px] btnimage"
              onClick={() => handleClick(6)}
              disabled={isDisabled}
            >
              <img
                className={`relative z-10 ${isDisabled ? "filter grayscale" : ""}`}
                src={Base}
                alt="Base"
              />
              <p className="absolute inset-[40px] ml-[85px] font-mmcOfficeBold text-[23px] w-60 text-center italic text-white tracking-wider z-20">
                SMALL TURNING RADIUS
              </p>
            </button>
          </div>
        </div>
      </div>
      <p className="absolute bottom-12 text-white uppercase italic font-mmcOffice text-2xl text-center">
        Press the button to know more details
      </p>
      <button
        className="absolute bottom-5 left-5 h-auto w-52"
        onClick={() => handleBack()}
        disabled={isDisabled}
      >
        <img
          className={`${isDisabled ? "filter grayscale" : ""}`}
          src={Back}
          alt="Go Back"
        />
      </button>
    </div>
  );
};

export default InteriorSafety;
