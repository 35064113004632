import React, { useEffect, useRef, useState } from "react";
import Bg from "../../assets/interior.png";
import Base from "../../assets/ledinteractive/TABLET/scene1/base.png";
import Back from "../../assets/ledinteractive/TABLET/scene2/back.png";
import { useNavigate } from "react-router-dom";

const Interior = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [activeDot, setActiveDot] = useState(null);
  const popupRef1 = useRef(null);
  const popupRef2 = useRef(null);
  const popupRef3 = useRef(null);
  const popupRef4 = useRef(null);
  const navigate = useNavigate();

  const handleClick = (dotNumber) => {
    console.log(`Dot ${dotNumber} clicked`);
    setPopupVisible(true);
    setActiveDot(dotNumber);
  };

  const handleClose = () => {
    setPopupVisible(false);
    setActiveDot(null);
  };

  const handleClickOutside = (event) => {
    if (activeDot) {
      const refs = [popupRef1, popupRef2, popupRef3, popupRef4];
      const clickedInsideAnyPopup = refs.some(
        (ref) => ref.current && ref.current.contains(event.target)
      );
      if (!clickedInsideAnyPopup) {
        setPopupVisible(false);
        setActiveDot(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeDot]);

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="flex flex-col justify-center space-y-[800px]">
        <div className="space-y-[-70px]">
          <img className="h-auto w-[1000px]" src={Base} alt="Base" />
          <p className="text-white uppercase font-bold font-mmcOfficeBold text-4xl text-center">
            WHERE PRESTIGE MEETS INNOVATION
          </p>
        </div>
        <p className="text-white uppercase italic font-mmcOfficeBold text-2xl text-center">
          Press the button to know more details
        </p>
      </div>
      <div
        className="absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[550px] left-48 cursor-pointer breathing-dot"
        onClick={() => handleClick(1)}
      ></div>
      <div
        className="absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[560px] right-44 cursor-pointer breathing-dot"
        onClick={() => handleClick(2)}
      ></div>
      <div
        className="absolute z-30 bg-red-500 rounded-full w-5 h-5 bottom-[200px] left-[450px] cursor-pointer breathing-dot"
        onClick={() => handleClick(3)}
      ></div>
      <div
        className="absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[420px] left-[670px] cursor-pointer breathing-dot"
        onClick={() => handleClick(4)}
      ></div>
      <div
        className="absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[290px] left-[670px] cursor-pointer breathing-dot"
        onClick={() => handleClick(5)}
      ></div>
      <div
        className="absolute z-30 bg-red-500 rounded-full w-5 h-5 top-[350px] right-[370px] cursor-pointer breathing-dot"
        onClick={() => handleClick(6)}
      ></div>
      <button
        className="absolute bottom-5 left-5 h-auto w-52"
        onClick={() => navigate("/")}
      >
        <img className="" src={Back} alt="Go Back" />
      </button>
    </div>
  );
};

export default Interior;
